.Home {
  margin: 0 auto;
}

.Home-row {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 100px auto;
}

