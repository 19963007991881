.AppIcon {
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
  margin-left: 100px;
  text-decoration: none;
  color: #525252;
  background-color: #eeeeee;
  border-radius: 8px;
  box-shadow: 0 5px 28px 0 rgba(0, 0, 0, .5);
}

.AppIcon-icon {
  text-align: center;
}
